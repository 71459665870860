export default {
  methods: {

    notificationTitleRender(verb) {
      switch (verb) {
        case 'WELCOME_CEDANT':
          return 'Bienvenue !'
        case 'WELCOME_REPRENEUR':
          return 'Bienvenue !'
        case 'CANDIDATURE_CREATED':
          return 'Nouvelle candidature'
        case 'CANDIDATURE_VALIDATED':
          return 'Candidature validée !'
        case 'CANDIDATURE_REFUSED':
          return 'Candidature refusée'
        case 'P_IDENTIFICATION_PARTIES_VALIDATED':
        case 'P_VALORISATION_VALIDATED':
        case 'P_VALIDATED':
          return 'Phase des pourparlers validée'
        case 'P_IDENTIFICATION_PARTIES_UNVALIDATED':
        case 'P_VALORISATION_UNVALIDATED':
        case 'P_UNVALIDATED':
          return 'Phase des pourparlers invalidée'
        case 'A_VALIDATED':
          return "Phase d'audit validée"
        case 'A_UNVALIDATED':
          return "Phase d'audit invalidée"
      }
      return 'Notification non identifiée'
    },

    notificationDescriptionRender(notification) {
      if (notification.actor === null) {
        return ''
      }
      switch (notification.verb) {
        case 'WELCOME_CEDANT':
          return 'Nous vous souhaitons la bienvenue sur Garance à vos côtés !'
        case 'WELCOME_REPRENEUR':
          return 'Nous vous souhaitons la bienvenue sur Garance à vos côtés !'
        case 'CANDIDATURE_CREATED':
          return `Vous avez une nouvelle candidature de ${notification.actor.repreneur.user.first_name} ${notification.actor.repreneur.user.last_name}`
        case 'CANDIDATURE_VALIDATED':
          return `Votre candidature pour la reprise de l'entreprise ${notification.actor.offre_cession.entreprise_cedee.name} a été validée ! Vous pouvez passer à la phase de pourparlers`
        case 'CANDIDATURE_REFUSED':
          return `Votre candidature pour la reprise de l'entreprise ${notification.actor.offre_cession.entreprise_cedee.name} a été refusée`
        case 'P_IDENTIFICATION_PARTIES_VALIDATED':
          if (notification.json_data.is_cedant) {
            return `${notification.actor.candidature.repreneur.user.first_name} ${notification.actor.candidature.repreneur.user.last_name} a validé la phase "Identification des parties" des pourparlers`
          }
          else {
            return `${notification.actor.candidature.offre_cession.entreprise_cedee.cedant.user.first_name} ${notification.actor.candidature.offre_cession.entreprise_cedee.cedant.user.last_name} a validé la phase "Identification des parties" des pourparlers`
          }
        case 'P_IDENTIFICATION_PARTIES_UNVALIDATED':
          if (notification.json_data.is_cedant) {
            return `${notification.actor.candidature.repreneur.user.first_name} ${notification.actor.candidature.repreneur.user.last_name} a invalidée la phase "Identification des parties" des pourparlers`
          }
          else {
            return `${notification.actor.candidature.offre_cession.entreprise_cedee.cedant.user.first_name} ${notification.actor.candidature.offre_cession.entreprise_cedee.cedant.user.last_name} a invalidée la phase "Identification des parties" des pourparlers`
          }
        case 'P_VALORISATION_VALIDATED':
          if (notification.json_data.is_cedant) {
            return `${notification.actor.candidature.repreneur.user.first_name} ${notification.actor.candidature.repreneur.user.last_name} a validé la phase "Valorisation de l'entreprise" des pourparlers`
          }
          else {
            return `${notification.actor.candidature.offre_cession.entreprise_cedee.cedant.user.first_name} ${notification.actor.candidature.offre_cession.entreprise_cedee.cedant.user.last_name} a validé la phase "Valorisation de l'entreprise" des pourparlers`
          }
        case 'P_VALORISATION_UNVALIDATED':
          if (notification.json_data.is_cedant) {
            return `${notification.actor.candidature.repreneur.user.first_name} ${notification.actor.candidature.repreneur.user.last_name} a invalidée la phase "Valorisation de l'entreprise" des pourparlers`
          }
          else {
            return `${notification.actor.candidature.offre_cession.entreprise_cedee.cedant.user.first_name} ${notification.actor.candidature.offre_cession.entreprise_cedee.cedant.user.last_name} a invalidée la phase "Valorisation de l'entreprise" des pourparlers`
          }
        case 'P_VALIDATED':
          if (notification.json_data.is_cedant) {
            return `${notification.actor.candidature.repreneur.user.first_name} ${notification.actor.candidature.repreneur.user.last_name} a validé l'étape finale des pourparlers`
          }
          else {
            return `${notification.actor.candidature.offre_cession.entreprise_cedee.cedant.user.first_name} ${notification.actor.candidature.offre_cession.entreprise_cedee.cedant.user.last_name} a validé l'étape finale des pourparlers`
          }
        case 'P_UNVALIDATED':
          if (notification.json_data.is_cedant) {
            return `${notification.actor.candidature.repreneur.user.first_name} ${notification.actor.candidature.repreneur.user.last_name} a invalidée l'étape finale des pourparlers`
          }
          else {
            return `${notification.actor.candidature.offre_cession.entreprise_cedee.cedant.user.first_name} ${notification.actor.candidature.offre_cession.entreprise_cedee.cedant.user.last_name} a invalidée l'étape finale des pourparlers`
          }

        case 'A_VALIDATED':
          if (notification.json_data.is_cedant) {
            return `${notification.actor.candidature.repreneur.user.first_name} ${notification.actor.candidature.repreneur.user.last_name} a validé l'étape ${notification.json_data.section}`
          }
          else {
            return `${notification.actor.candidature.offre_cession.entreprise_cedee.cedant.user.first_name} ${notification.actor.candidature.offre_cession.entreprise_cedee.cedant.user.last_name} a validé l'étape ${notification.json_data.section}`
          }

        case 'A_UNVALIDATED':
          if (notification.json_data.is_cedant) {
            return `${notification.actor.candidature.repreneur.user.first_name} ${notification.actor.candidature.repreneur.user.last_name} a invalidé l'étape ${notification.json_data.section}`
          }
          else {
            return `${notification.actor.candidature.offre_cession.entreprise_cedee.cedant.user.first_name} ${notification.actor.candidature.offre_cession.entreprise_cedee.cedant.user.last_name} a invalidé l'étape ${notification.json_data.section}`
          }


    }
      return 'Notification non identifiée'
    },

    notificationLinkRender(actor, verb) {
      switch (verb) {
        case 'WELCOME_CEDANT':
        case 'CANDIDATURE_CREATED':
          return '/cedant/tableau-de-bord'
          case 'WELCOME_REPRENEUR':
        case 'CANDIDATURE_VALIDATED':
        case 'CANDIDATURE_REFUSED':
          return '/repreneur/tableau-de-bord'
        case 'P_IDENTIFICATION_PARTIES_VALIDATED':
        case 'P_IDENTIFICATION_PARTIES_UNVALIDATED':
        case 'P_VALORISATION_VALIDATED':
        case 'P_VALORISATION_UNVALIDATED':
        case 'P_VALIDATED':
        case 'P_UNVALIDATED':
          return `/pourparler/${actor.id}`
        case 'A_VALIDATED':
        case 'A_UNVALIDATED':
          if (actor.audit_step_ongoing === 3) {
            return `/audit/paiement-prix/${actor.paiement_prix_id}`
          }
          else if (actor.audit_step_ongoing === 2) {
            return `/audit/entreprise-cedee/${actor.id}`
          }
          else {
            return `/audit/situation-juridique/${actor.situation_juridique_id}`
          }
      }
      return ''
    },

    notificationDateRender(difference) {
      //difference is in second
      if (difference <= 600) {
        return 'Juste maintenant'
      }
      else if (difference <= 86400) {
        return 'Il y a ' + String(parseInt(difference/3600)) + ' heures'
      }
      else if (difference <= 2592000){
        return 'Il y a ' + String(parseInt(difference/86400)) + ' jours'
      }
      else if (difference <= 31536000){
        return 'Il y a ' + String(parseInt(difference/2592000)) + ' mois'
      }
      else {
        return 'Il y a ' + String(parseInt(difference/31536000)) + ' ans'
      }
    },
  }
}
