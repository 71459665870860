import { getField, updateField } from 'vuex-map-fields';

const withoutLocalStorageFields = new Set(['hasAcceptedCGUCGV', 'password'])

const state = {
  // GLOBAL
  cedantRegistrationStep: localStorage.getItem('garance-cession-registration-cedantRegistrationStep') || 1,
  repreneurRegistrationStep: localStorage.getItem('garance-cession-registration-repreneurRegistrationStep') || 1,

  //// ------------ CEDANT -------------------
  cedant: {
    annonce: {},
    company: {
      cedantCompanyName: localStorage.getItem('garance-cession-registration-cedant-company-cedantCompanyName') || '',
    },
    entreprise_cedee: {},
    cedantHasAcceptedRelaiAnnonce: JSON.parse(localStorage.getItem('garance-cession-registration-cedant-cedantHasAcceptedRelaiAnnonce')) || false,
    cedantHasAuthorizedContact: JSON.parse(localStorage.getItem('garance-cession-registration-cedant-cedantHasAuthorizedContact')) || false,
    hasIdentifyRepreneur: JSON.parse(localStorage.getItem('garance-cession-registration-cedant-hasIdentifyRepreneur')) || false,
    cedantIsPersonnePhysique: JSON.parse(localStorage.getItem('garance-cession-registration-cedant-cedantIsPersonnePhysique')),
    offreCession: {
      cedantOffreCessionElementSold: localStorage.getItem('garance-cession-registration-cedant-offreCession-cedantOffreCessionElementSold') || '',
      cedantOffreCessionPrice: localStorage.getItem('garance-cession-registration-cedant-offreCession-cedantOffreCessionPrice') || '',
    },
    cedantPersonalProject: localStorage.getItem('garance-cession-registration-cedant-cedantPersonalProject') || '',
    repreneur: {
      company: {
        cedantRepreneurCompanyName: localStorage.getItem('garance-cession-registration-cedant-repreneur-company-cedantRepreneurCompanyName') || '',
        cedantRepreneurCompanySiren: localStorage.getItem('garance-cession-registration-cedant-repreneur-company-cedantRepreneurCompanySiren') || '',
      },
      cedantRepreneurIsPersonnePhysique: JSON.parse(localStorage.getItem('garance-cession-registration-cedant-repreneur-cedantRepreneurIsPersonnePhysique')),
      user: {
        cedantRepreneurUserFirstName: localStorage.getItem('garance-cession-registration-cedant-repreneur-user-cedantRepreneurUserFirstName') || '',
        cedantRepreneurUserLastName: localStorage.getItem('garance-cession-registration-cedant-repreneur-user-cedantRepreneurUserLastName') || '',
        cedantRepreneurUserUsername: localStorage.getItem('garance-cession-registration-cedant-repreneur-user-cedantRepreneurUserUsername') || '',
      },
    },
    user: {
      cedantUserHasAcceptedCGUCGV: false,
      cedantUserFirstName: localStorage.getItem('garance-cession-registration-cedant-user-cedantUserFirstName') || '',
      cedantUserLastName: localStorage.getItem('garance-cession-registration-cedant-user-cedantUserLastName') || '',
      cedantUserPassword: '',
      cedantUserPasswordConfirmation: '',
      cedantUserPhoneNumber: localStorage.getItem('garance-cession-registration-cedant-user-cedantUserPhoneNumber') || '',
      cedantUserUsername: localStorage.getItem('garance-cession-registration-cedant-user-cedantUserUsername') || '',
    },
  },
  //// ------------ REPRENEUR ----------------
  repreneur: {
    cedant: {
      company: {
        repreneurCedantCompanyName: localStorage.getItem('garance-cession-registration-repreneur-cedant-company-repreneurCedantCompanyName') || '',
        repreneurCedantCompanySiren: localStorage.getItem('garance-cession-registration-repreneur-cedant-company-repreneurCedantCompanySiren') || '',
      },
      repreneurCedantIsPersonnePhysique: JSON.parse(localStorage.getItem('garance-cession-registration-repreneur-cedant-repreneurCedantIsPersonnePhysique')),
      entreprise_cedee: {
        repreneurCedantEntrepriseCedeeName: localStorage.getItem('garance-cession-registration-repreneur-cedant-entreprise_cedee-repreneurCedantEntrepriseCedeeName') || '',
        repreneurCedantEntrepriseCedeeSiren: localStorage.getItem('garance-cession-registration-repreneur-cedant-entreprise_cedee-repreneurCedantEntrepriseCedeeSiren') || '',
      },
      user: {
        repreneurCedantUserFirstName: localStorage.getItem('garance-cession-registration-repreneur-cedant-user-repreneurCedantUserFirstName') || '',
        repreneurCedantUserLastName: localStorage.getItem('garance-cession-registration-repreneur-cedant-user-repreneurCedantUserLastName') || '',
        repreneurCedantUserUsername: localStorage.getItem('garance-cession-registration-repreneur-cedant-user-repreneurCedantUserUsername') || '',
      }
    },
    company: {},
    existingEntrepriseCedeeSiren: localStorage.getItem('garance-cession-registration-repreneur-existingEntrepriseCedeeSiren') || null,
    repreneurFormationPrecision: localStorage.getItem('garance-cession-registration-repreneur-repreneurFormationPrecision') || '',
    repreneurHasExperienceGestionEntreprise: JSON.parse(localStorage.getItem('garance-cession-registration-repreneur-repreneurHasExperienceGestionEntreprise')),
    repreneurHasFormationDomaineActivite: JSON.parse(localStorage.getItem('garance-cession-registration-repreneur-repreneurHasFormationDomaineActivite')),
    hasIdentifyCompany: JSON.parse(localStorage.getItem('garance-cession-registration-repreneur-hasIdentifyCompany')),
    repreneurIsPersonnePhysique: JSON.parse(localStorage.getItem('garance-cession-registration-repreneur-repreneurIsPersonnePhysique')),
    repreneurPersonalProject: localStorage.getItem('garance-cession-registration-repreneur-repreneurPersonalProject') || '',
    repreneurPersonalProjectPrecision: localStorage.getItem('garance-cession-registration-repreneur-repreneurPersonalProjectPrecision') || '',
    user: {
      repreneurUserHasAcceptedCGUCGV: false,
      repreneurUserHasAuthorizedContact: JSON.parse(localStorage.getItem('garance-cession-registration-repreneur-repreneurUserHasAuthorizedContact')) || false,
      repreneurUserFirstName: localStorage.getItem('garance-cession-registration-repreneur-user-repreneurUserFirstName') || '',
      repreneurUserLastName: localStorage.getItem('garance-cession-registration-repreneur-user-repreneurUserLastName') || '',
      repreneurUserPassword: '',
      repreneurUserPasswordConfirmation: '',
      repreneurUserPhoneNumber: localStorage.getItem('garance-cession-registration-repreneur-user-repreneurUserPhoneNumber') || '',
      repreneurUserUsername: localStorage.getItem('garance-cession-registration-repreneur-user-repreneurUserUsername') || '',
    },
  }
}

const getters = {
  getCedantOffreCessionPrice: state => {
    return state.cedant.offreCession.cedantOffreCessionPrice
  },
  getField,
  getCedantRegistrationForm: state => {
    const registrationForm = {
      'annonce': null,
      'company': null,
      'entreprise_cedee': {},
      'has_accepted_relai_annonce': state.cedant.cedantHasAcceptedRelaiAnnonce,
      'has_authorized_contact': state.cedant.cedantHasAuthorizedContact,
      'has_accepted_cgu_cgv': state.cedant.user.cedantUserHasAcceptedCGUCGV,
      'has_identify_repreneur': state.cedant.hasIdentifyRepreneur,
      'is_personne_physique': state.cedant.cedantIsPersonnePhysique,
      'offre_cession':  {
        'element_sold': state.cedant.offreCession.cedantOffreCessionElementSold,
        'price': state.cedant.offreCession.cedantOffreCessionPrice
      },
      'personal_project': state.cedant.cedantPersonalProject,
      'phone_number': state.cedant.user.cedantUserPhoneNumber,
      'repreneur': null,
      'user': {
          'first_name': state.cedant.user.cedantUserFirstName,
          'last_name': state.cedant.user.cedantUserLastName,
          'password': state.cedant.user.cedantUserPassword,
          'username': state.cedant.user.cedantUserUsername,
      },
    }

    if (!state.cedant.cedantIsPersonnePhysique) {
      registrationForm['company'] = {
        'name': state.cedant.company.cedantCompanyName,
      }
    }

    if (state.cedant.hasIdentifyRepreneur) {
      registrationForm['repreneur'] = {
        'company': null,
        'has_identify_cedant': true,
        'is_personne_physique': state.cedant.repreneur.cedantRepreneurIsPersonnePhysique,
        "user": {
            "first_name": state.cedant.repreneur.user.cedantRepreneurUserFirstName,
            "last_name": state.cedant.repreneur.user.cedantRepreneurUserLastName,
            "username": state.cedant.repreneur.user.cedantRepreneurUserUsername
        },
      }
      if (!state.cedant.repreneur.cedantRepreneurIsPersonnePhysique) {
        registrationForm['repreneur']['company'] = {
          'name': state.cedant.repreneur.company.cedantRepreneurCompanyName,
          "siren": state.cedant.repreneur.company.cedantRepreneurCompanySiren,
        }
      }
    }
    else {
      registrationForm['annonce'] = {}
    }
    return registrationForm
  },

  getRepreneurRegistrationForm: state => {
    const registrationForm = {
      'cedant': null,
      'company': null,
      'existingEntrepriseCedeeSiren': state.repreneur.existingEntrepriseCedeeSiren,
      'has_accepted_cgu_cgv': state.repreneur.user.repreneurUserHasAcceptedCGUCGV,
      'has_authorized_contact': state.repreneur.user.repreneurUserHasAuthorizedContact,
      'has_identify_cedant': state.repreneur.hasIdentifyCompany,
      'is_personne_physique': state.repreneur.repreneurIsPersonnePhysique,
      'personal_project': state.repreneur.repreneurPersonalProject,
      'personal_project_precision': state.repreneur.repreneurPersonalProjectPrecision,
      'phone_number': state.repreneur.user.repreneurUserPhoneNumber,
      'user': {
          'first_name': state.repreneur.user.repreneurUserFirstName,
          'last_name': state.repreneur.user.repreneurUserLastName,
          'password': state.repreneur.user.repreneurUserPassword,
          'username': state.repreneur.user.repreneurUserUsername,
      },
    }

    if (!state.repreneur.repreneurIsPersonnePhysique) {
      registrationForm['company'] = {}
    }

    if (state.repreneur.hasIdentifyCompany && !state.repreneur.existingEntrepriseCedeeSiren) {
      registrationForm['cedant'] = {
        'company': null,
        'entreprise_cedee': {
          'name': state.repreneur.cedant.entreprise_cedee.repreneurCedantEntrepriseCedeeName,
          "siren": state.repreneur.cedant.entreprise_cedee.repreneurCedantEntrepriseCedeeSiren,
        },
        'has_identify_repreneur': true,
        'is_personne_physique': state.repreneur.cedant.repreneurCedantIsPersonnePhysique,
        'user': {
          'first_name': state.repreneur.cedant.user.repreneurCedantUserFirstName,
          'last_name': state.repreneur.cedant.user.repreneurCedantUserLastName,
          'username': state.repreneur.cedant.user.repreneurCedantUserUsername,
        }
      }
      if (!state.repreneur.cedant.repreneurCedantIsPersonnePhysique) {
        registrationForm['cedant']['company'] = {
          'name': state.repreneur.cedant.company.repreneurCedantCompanyName,
          "siren": state.repreneur.cedant.company.repreneurCedantCompanySiren,
        }
      }
    }
    return registrationForm
  }
}

const mutations = {
  updateFieldWithLocalStorage(state, field) {
    if (!withoutLocalStorageFields.has(field.path)) {
      if (Array.isArray(field.value) || typeof field.value === 'boolean') {
        localStorage.setItem(`garance-cession-registration-${field.path.replaceAll('.', '-')}`, JSON.stringify(field.value))
      }
      else {
        localStorage.setItem(`garance-cession-registration-${field.path.replaceAll('.', '-')}`, field.value)
      }
    }
    updateField(state, field);
  },
}

export default {
  state,
  getters,
  mutations,
}
