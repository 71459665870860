<template>
  <div class="LoadingComponent">
    <loading
      :active.sync="isLoadingFromAPI"
      :can-cancel="false"
      :is-full-page="true"
      color="#33304c">
    </loading>
  </div>
</template>

<script>
export default {
  name: 'LoadingComponent',
  computed : {
    isLoadingFromAPI: {
      get () {
        return this.$store.state.isAPILoading
      }
    },
  }
}
</script>
