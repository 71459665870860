import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import registration from './modules/registration'
import user from './modules/user'


Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const state = {
  isGlobalErrorModal: false,
  isAPILoading: false,
}

const mutations = {
  set (state, [variable, value]) {
    state[variable] = value
  },
  openGlobalErrorModal (state) {
    state.isGlobalErrorModal = true
  },
  closeGlobalErrorModal (state) {
    state.isGlobalErrorModal = false
  },
  loadFromAPI (state) {
    state.isAPILoading = true
  },
  endLoadingFromAPI (state) {
    state.isAPILoading = false
  },
}


export default new Vuex.Store({
  modules: {
    auth,
    registration,
    user,
  },
  strict: debug,
  state,
  mutations
})
