import axios from 'axios'
import { apiBaseUrl } from '@/variables/localVariables'
import store from '@/store/store'
import router from '@/router'

const axios_instance = axios.create({
  baseURL:apiBaseUrl,
});

var isRefreshing = false

//Add a response interceptor
axios_instance.interceptors.response.use(
  function (response) {
    store.dispatch('AUTH_INSPECT')
      .catch(() => {
        store.commit('AUTH_LOGOUT')
        router.push('/se-connecter')
      })
    return response
  },
  function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && originalRequest.url === apiBaseUrl +'/auth/token/refresh') {
     router.push('/se-connecter');
     return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true
        originalRequest._retry = true
        const refreshToken = JSON.parse(store.state.auth.token).refresh

        return axios_instance.post(apiBaseUrl +'/auth/token/refresh', {"refresh": refreshToken})
         .then(res => {
           if (res.status === 200) {
             store.commit('AUTH_REFRESH', res.data)
             originalRequest.headers['Authorization'] = 'Bearer ' + res.data.access
             return axios_instance(originalRequest)
           }
           else {
             store.commit('AUTH_LOGOUT')
             router.push('/se-connecter')
           }
         })
         .catch(() => {
           store.commit('AUTH_LOGOUT')
           router.push('/se-connecter')
         })
         .finally(() => {
           isRefreshing = false
         })
     }
     originalRequest.headers['Authorization'] = 'Bearer ' + JSON.parse(store.state.auth.token).access
     return axios_instance(originalRequest)
   }
   return Promise.reject(error)
});

export class APIConnected {

  createAuditItem (token, sectionType, data) {
    const url = apiBaseUrl + `/create-audit-item/${sectionType}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'multipart/form-data'
    }
    return axios_instance.post(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  createAuditSectionInformation (token, informationType, data) {
    const url = apiBaseUrl + `/create-audit-section-information/${informationType}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    return axios_instance.post(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  createPourparlerDocument (token, data) {
    const url = apiBaseUrl + `/create-pourparler-documents`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'multipart/form-data'
    }
    return axios_instance.post(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  deleteAuditItem (token, sectionType, id) {
    const url = apiBaseUrl + `/audit-item-details/${sectionType}/${id}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    return axios_instance.delete(
      url,
      {
        'headers': headers,
      }
    )
  }

  deleteAuditSectionInformation (token, informationType, id) {
    const url = apiBaseUrl + `/audit-section-information-details/${informationType}/${id}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    return axios_instance.delete(
      url,
      {
        'headers': headers,
      }
    )
  }

  deletePourparlerDocument (token, id) {
    const url = apiBaseUrl + `/pourparler-document-details/${id}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    return axios_instance.delete(
      url,
      {
        'headers': headers,
      }
    )
  }

  downloadRapportCession (token, closingPk) {
    //https://thewebtier.com/snippets/download-files-with-axios/
    const url = apiBaseUrl + `/download-rapport-cession/${closingPk}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(
      url,
      {
        'headers': headers,
        'responseType': 'blob', // important
      },
    )
  }

  downloadClosingDocument (token, closingPk) {
    //https://thewebtier.com/snippets/download-files-with-axios/
    const url = apiBaseUrl + `/download-acte-cession/${closingPk}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(
      url,
      {
        'headers': headers,
        'responseType': 'blob', // important
      },
    )
  }

  downloadLettreIntention (token, pourparlerPk) {
    //https://thewebtier.com/snippets/download-files-with-axios/
    const url = apiBaseUrl + `/download-lettre-intention/${pourparlerPk}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(
      url,
      {
        'headers': headers,
        'responseType': 'blob', // important
      },
    )
  }

  downloadAuditDocument (token, sectionType,  id) {
    //https://thewebtier.com/snippets/download-files-with-axios/
    const url = apiBaseUrl + `/download-audit-document/${sectionType}/${id}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(
      url,
      {
        'headers': headers,
        'responseType': 'blob', // important
      },
    )
  }

  downloadPourparlerDocument (token, id) {
    //https://thewebtier.com/snippets/download-files-with-axios/
    const url = apiBaseUrl + `/download-pourparler-document/${id}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(
      url,
      {
        'headers': headers,
        'responseType': 'blob', // important
      },
    )
  }

  getAudit(token, id) {
    const url = apiBaseUrl + `/audit-details/${id}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getAuditSection(token, sectionType, id) {
    const url = apiBaseUrl + `/audit-section-details/${sectionType}/${id}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getClosing(token, id) {
    const url = apiBaseUrl + `/closing-details/${id}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getTenLastNotifications (token) {
    const url = apiBaseUrl + '/ten-last-notifications'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }


  getAllNotifications (token, url) {

    /* Be careful in this page because a URL with the page is needed !!! */

    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getPourparler(token, id) {
    const url = apiBaseUrl + `/pourparler-details/${id}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }


  markAllNotificationsAsRead (token) {
    const url = apiBaseUrl + '/mark-all-notifications-as-read'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(url, {}, {'headers': headers,})
  }

  markNotificationAsClicked (token, notification_pk) {
    const url = apiBaseUrl + `/mark-notification-as-clicked/` + notification_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(url, {}, {'headers': headers,})
  }


  modifyAudit (token, id, data) {
    const url = apiBaseUrl + `/audit-details/${id}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  modifyAuditSection (token, sectionType, id, data) {
    const url = apiBaseUrl + `/audit-section-details/${sectionType}/${id}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  modifyAuditDocument (token, id, data) {
    const url = apiBaseUrl + `/audit-document-details/${id}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'multipart/form-data'
    }
    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  modifyAuditSectionInformation (token, informationType, id, data) {
    const url = apiBaseUrl + `/audit-section-information-details/${informationType}/${id}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }


  modifyClosing (token, id, data) {
    const url = apiBaseUrl + `/closing-details/${id}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  modifyPourparler (token, id, data) {
    const url = apiBaseUrl + `/pourparler-details/${id}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  modifyPourparlerDocument (token, id, data) {
    const url = apiBaseUrl + `/pourparler-document-details/${id}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'multipart/form-data'
    }
    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }


  validateAuditItem (token, sectionType, id, data) {
    const url = apiBaseUrl + `/validate-audit-item/${sectionType}/${id}`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }
}
