<template>
  <div class="cookiesBandeauComponent">
    <b-modal ref="cookieBandeauModal" id="cookieBandeauModal" size="lg" hide-footer hide-header no-close-on-backdrop>
      <div class="cookieBandeauModal-contenu">
        <div class="row ">
          <div class="col-12">
            <p class="gavc-p">
              Nous utilisons des cookies pour faciliter votre navigation et vous garantir
              la meilleure expérience sur notre site web. Vous avez le choix d’accepter
              ou de refuser les cookies utilisés pour le bon fonctionnement du site et
              la mesure d’audience qui ne sont pas exemptés de consentement.
              En cliquant sur « Tout Accepter », l'ensemble de ces cookies
              seront autorisés pour permettre une navigation optimisée.
              Vous pouvez également paramétrer vos choix et accepter les cookies ou non pour chaque cas, ou « Tout Refuser » selon vos préférences.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-right">
             <a
              class="cookie-bandeau-link"
              @click="$refs.cookieBandeauPreferencesModal.show()"
            >
              Gérer mes préférences <i class="gavc-icon gavc-icon-arrow-right"></i>
            </a>
            <a
              class="ml-2 cookie-bandeau-link"
              href="https://www.garance-a-vos-cotes.fr/politique-de-confidentialite/"
              target="_blank"
              rel="noreferrer">
              En savoir plus <i class="gavc-icon gavc-icon-arrow-right"></i>
            </a>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-6">
            <button class="gavc-btn gavc-btn--bone" @click="refuseAll" pill>Tout refuser ></button>
          </div>
          <div class="col-6 text-right">
            <button class="gavc-btn gavc-btn--bone" @click="acceptAll" pill>Tout accepter ></button>
          </div>
        </div>
      </div>
    </b-modal>


    <b-modal ref="cookieBandeauPreferencesModal" id="cookieBandeauPreferencesModal" size="lg" hide-footer hide-header>
      <div class="row">
        <div class="col-12">
          <div class="text-center">
            <strong class="gavc-h3">Gestion de vos préférences sur les cookies</strong>
          </div>
          <p class="mt-4 gavc-p">
            Certaines fonctionnalités de ce site (partage de contenus sur les réseaux sociaux, lecture directe de vidéos)
            s’appuient sur des services proposés par des sites tiers. Ces fonctionnalités déposent des cookies permettant
            notamment à ces sites de tracer votre navigation. Ces cookies ne sont déposés que si vous donnez votre accord.
            Vous pouvez vous informer sur la nature des cookies déposés, les accepter ou les refuser soit globalement pour
            l’ensemble du site et l’ensemble des services, soit service par service.
          </p>
        </div>
      </div>
      <div class="row gavc-bg-bizarre gavc-text-martinique pt-4 pb-4">
        <div class="col-12">
          <strong class="gavc-title-price">Cookies obligatoires</strong>
          <p class="gavc-p">
            Ces cookies nous permettent d'assurer le bon fonctionnement du site et sont strictement nécessaires à la fourniture d'un service que vous avez expressément demandé.
          </p>
          <div class="row">
            <div class="col-12 text-right">
              <button class="gavc-btn" disabled> Autoriser </button>
              <button class="gavc-btn gavc-btn--bone" disabled> Désactiver </button>
            </div>
          </div>
        </div>
      </div>
      <!--<div class="row cookieBandeauPreferencesFacultative">
        <div class="col-12">
          <strong class="cookieBandeauPreferencesFacultativeTitle">Cookies publicitaires</strong>
          <p>
            Ils nous permettent de cibler vos centres d'intérêt et de vous proposer des publicités
            en rapport avec ceux-ci, sur le site et en dehors. Notez que leur refus ne fera pas obstacle à l'affichage de publicités non ciblées.
          </p>
          <div class="row">
            <div class="col-12 text-right">
              <b-button class="activeCookiesButton activeAuthorizeCookiesButton"> Autoriser </b-button>
              <b-button class="activeCookiesButton disableAuthorizeCookiesButton ml-3"> Désactiver </b-button>
            </div>
          </div>
        </div>
      </div>-->
      <div class="row gavc-bg-white gavc-text-spun pt-4 pb-4">
        <div class="col-12">
          <strong class="gavc-title-price">Cookies liés aux réseaux sociaux</strong>
          <p class="gavc-p">
            Ces cookies sont générés par les modules et boutons de partage de réseaux sociaux et permettent,
            lorsque vous les utilisez, d'informer les réseaux sociaux de votre visite sur notre site.
            Ces derniers sont susceptibles de déposer des cookies sur votre ordinateur selon les règles
            de gestion qu'ils ont définies et que nous vous invitons à consulter sur leur site.
          </p>
          <div class="row">
            <div class="col-12 text-right">
              <button
                :class="isSocialNetworkAnalysis ? 'gavc-btn' : 'gavc-btn gavc-btn--bone'"
                @click="isSocialNetworkAnalysis=true"
              >
                Autoriser
              </button>
              <button
                :class="!isSocialNetworkAnalysis ? 'gavc-btn' : 'gavc-btn gavc-btn--bone'"
                @click="isSocialNetworkAnalysis=false"
              >
                Désactiver
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row gavc-bg-white gavc-text-spun pt-4 pb-4">
        <div class="col-12">
          <strong class="gavc-title-price">Cookies d'analyse d'audience</strong>
          <p class="gavc-p">
            Ces cookies nous permettent d'établir des statistiques anonymes de fréquentation des pages de notre site afin d'en améliorer l'ergonomie et le contenu.
          </p>
          <div class="row">
            <div class="col-12 text-right">
              <button
                :class="isCookiesAnalysis ? 'gavc-btn' : 'gavc-btn gavc-btn--bone'"
                @click="isCookiesAnalysis=true"
              >
                Autoriser
              </button>
              <button
                :class="!isCookiesAnalysis ? 'gavc-btn' : 'gavc-btn gavc-btn--bone'"
                @click="isCookiesAnalysis=false"
              >
                Désactiver
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row pt-4 pb-4">
        <div class="col-12 text-center">
          <button class="gavc-btn gavc-btn--white" @click="save">Enregistrer</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { setOptions, bootstrap, optIn, optOut } from 'vue-gtag'

export default {
  name: 'cookiesBandeauComponent',
  data: function (){
    return {
      isCookiesAnalysis: true,
      isSocialNetworkAnalysis: true
    }
  },
  mounted () {
    if (this.$cookies.get('cookie-notice-accepted') == null) {
      this.$refs.cookieBandeauModal.show()
    }
    if (this.$cookies.get('cookie-analysis') == false) {
      this.isCookiesAnalysis = false
      optOut()
      setOptions({
        enabled: false
      })
    }
    else {
      this.isCookiesAnalysis = true
      setOptions({
        config: { id: 'G-CK8KPX503V' }
      })
      bootstrap().then(() => {
        // all done!
      })
      optIn()
      this.changeCookieValue("cookie-analysis", true)
    }

    if (this.$cookies.get('cookie-social-network-analyses') == false) {
      this.removeFbScript()
    }
    else {
      let fbScript = document.getElementById("social-network-facebook-script")
      if (!fbScript) {
        this.createFbScript()
      }
      this.changeCookieValue("cookie-social-network-analyses", true)
    }
  },
  methods: {
    acceptAll () {
      this.changeCookieValue("cookie-notice-accepted", true)
      this.changeCookieValue("cookie-analysis", true)
      this.changeCookieValue("cookie-necessary", true)
      this.changeCookieValue("cookie-social-network-analyses", true)
      this.$refs.cookieBandeauModal.hide();
      this.$refs.cookieBandeauPreferencesModal.hide()
      setOptions({
        config: { id: 'G-CK8KPX503V' }
      })
      bootstrap().then(() => {
        // all done!
      })
      optIn()
      let fbScript = document.getElementById("social-network-facebook-script")
      if (!fbScript) {
        this.createFbScript()
      }
    },
    refuseAll () {
      this.changeCookieValue("cookie-notice-accepted", false)
      this.changeCookieValue("cookie-analysis", false)
      this.changeCookieValue("cookie-necessary", false)
      this.changeCookieValue("cookie-social-network-analyses", false)
      this.$refs.cookieBandeauModal.hide();
      this.$refs.cookieBandeauPreferencesModal.hide()
      optOut()
      setOptions({
        enabled: false
      })
      this.removeFbScript()
    },
    changeCookieValue(cookieName, value) {
      this.$cookies.set(cookieName, value, 60 * 60 * 24 * 90)
    },
    save () {
      if (this.isCookiesAnalysis) {
        this.changeCookieValue("cookie-analysis", true)
        setOptions({
          config: { id: 'G-CK8KPX503V' }
        })
        bootstrap().then(() => {
          // all done!
        })
        optIn()
      }
      else {
        this.changeCookieValue("cookie-analysis", false)
        optOut()
        setOptions({
          enabled: false
        })
      }

      if (this.isSocialNetworkAnalysis) {
        this.changeCookieValue("cookie-social-network-analyses", true)
        this.createFbScript()
      }
      else {
        this.changeCookieValue("cookie-social-network-analyses", false)
        this.removeFbScript()
      }

      this.changeCookieValue("cookie-notice-accepted", true)
      this.changeCookieValue("cookie-necessary", true)

      this.$refs.cookieBandeauModal.hide();
      this.$refs.cookieBandeauPreferencesModal.hide()
    },

    createFbScript() {
      const facebookTagConfigScript = document.createElement('script')
      facebookTagConfigScript.id = "social-network-facebook-script"
      const facebookTagManagerConfigScriptInlineCode = document.createTextNode(
        `!function(f,b,e,v,n,t,s){
if(f.fbq)return;n=f.fbq=function(){
n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
document,'script','https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '708964430293393');
fbq('track', 'PageView');`)
      facebookTagConfigScript.appendChild(facebookTagManagerConfigScriptInlineCode);
      this.facebookTagConfigScript = document.head.appendChild(facebookTagConfigScript)
    },
    removeFbScript() {
      let fbScript = document.getElementById("social-netork-facebook-script")
      if (fbScript) {
        fbScript.remove()
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/_variables.scss';

#cookieBandeauModal___BV_modal_content_ {
  background-color: $green-darken-1 !important;
  color: white;
}
#cookieBandeauPreferencesModal___BV_modal_content_ {
  background-color: $green-darken-1 !important;
  color: white;
}


.cookieBandeauModal-contenu {
  padding: 25px;
}

.cookie-bandeau-link {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.cookie-bandeau-link:hover {
  color: white;
}
</style>
