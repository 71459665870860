import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import Vuex from 'vuex'

import {
  BadgePlugin, BAlert, ButtonGroupPlugin, CardPlugin, LayoutPlugin, ModalPlugin, ToastPlugin, DropdownPlugin,
  NavPlugin, NavbarPlugin, ButtonPlugin,
  FormPlugin, FormCheckboxPlugin, FormGroupPlugin,
  FormInputPlugin, InputGroupPlugin, FormSelectPlugin,
  FormFilePlugin, FormTextareaPlugin, SpinnerPlugin,
  TablePlugin, TabsPlugin, ImagePlugin, FormRadioPlugin,
  FormDatepickerPlugin, PopoverPlugin, TooltipPlugin, SidebarPlugin
} from 'bootstrap-vue'

import "./assets/scss/custom.scss"

import 'vue-awesome/icons/building'
import 'vue-awesome/icons/check-circle'
import 'vue-awesome/icons/chevron-down'
import 'vue-awesome/icons/chevron-right'
import 'vue-awesome/icons/cloud-download-alt'
import 'vue-awesome/icons/edit'
import 'vue-awesome/icons/eye'
import 'vue-awesome/icons/file-alt'
import 'vue-awesome/icons/file-contract'
import 'vue-awesome/icons/filter'
import 'vue-awesome/icons/hand-pointer'
import 'vue-awesome/icons/image'
import 'vue-awesome/icons/info-circle'
import 'vue-awesome/icons/laugh-beam'
import 'vue-awesome/icons/plus'
import 'vue-awesome/icons/search'
import 'vue-awesome/icons/share-alt'
import 'vue-awesome/icons/sync'
import 'vue-awesome/icons/times-circle'
import 'vue-awesome/icons/trash-alt'
import 'vue-awesome/icons/user-circle'
import 'vue-awesome/icons/users'

import browserDetect from "vue-browser-detect-plugin";
import Icon from 'vue-awesome/components/Icon'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueDayjs from 'vue-dayjs-plugin'
import 'dayjs/locale/fr'

import Multiselect from 'vue-multiselect'
import "vue-multiselect/dist/vue-multiselect.min.css"

import VueMask from 'v-mask'
import money from 'v-money'

import VueGtag from "vue-gtag";
import VueCookies from 'vue-cookies'

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

Vue.config.productionTip = false

Vue.use(Vuex)
Vue.use(BadgePlugin)
Vue.use(ButtonGroupPlugin)
Vue.use(CardPlugin)
Vue.use(LayoutPlugin)
Vue.use(ModalPlugin)
Vue.use(ToastPlugin)
Vue.use(DropdownPlugin)
Vue.use(NavPlugin)
Vue.use(NavbarPlugin)
Vue.use(ButtonPlugin)
Vue.use(FormPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormInputPlugin)
Vue.use(InputGroupPlugin)
Vue.use(SpinnerPlugin)
Vue.use(FormFilePlugin)
Vue.use(FormTextareaPlugin)
Vue.use(TablePlugin)
Vue.use(TabsPlugin)
Vue.use(FormSelectPlugin)
Vue.use(ImagePlugin)
Vue.use(FormRadioPlugin)
Vue.use(FormDatepickerPlugin)
Vue.use(PopoverPlugin)
Vue.use(TooltipPlugin)
Vue.use(SidebarPlugin)

Vue.use(VueDayjs)

Vue.use(browserDetect)
Vue.use(VueMask)
Vue.use(money, {precision: 2})

Vue.component('vue-multiselect-input', Multiselect)
Vue.component('b-alert', BAlert)
Vue.component('loading', Loading)
Vue.component('v-icon', Icon)

Sentry.init({
  Vue,
  dsn: "https://48fcde6f7bd24b6e85ef734b005c67bf@o373339.ingest.sentry.io/6214864",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["app.garance-a-vos-cotes.fr", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
Vue.use(VueCookies)
Vue.use(VueGtag, {
  bootstrap: false
}, router);



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
