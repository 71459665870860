<template>
  <div class="home">
  </div>
</template>

<script>

export default {
  name: 'Home',
  computed: {
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    },
    token () {
      return this.$store.getters.TOKEN
    }
  },
  created () {
    if (!this.isAuthenticated) {
      this.$router.push('/se-connecter')
    }
    else if (this.userGroupContains(['REPRENEUR'])) {
      this.$router.push('/repreneur/tableau-de-bord')
    }
    else if (this.userGroupContains(['CEDANT'])) {
      this.$router.push('/cedant/tableau-de-bord')
    }
    else {
      this.$router.push('/se-connecter')
    }
  },
  methods: {
    userGroupContains(groupsList) {
      var userGroups = this.$store.getters.getUserGroups
      var userGroupsArray = []
      var is_contained = false
      if (userGroups) {
        try {
          var userGroupsJson = JSON.parse(userGroups)
          for (var i = 0; i < userGroupsJson.length; i++) {
            userGroupsArray.push(
              userGroupsJson[i]['name']
            )
          }
        }
        catch(error) {
          this.$store.dispatch('AUTH_LOGOUT').then(() => {
            this.$router.push('/se-connecter')
            this.$bvToast.toast(`Votre session a expiré, veuillez vous reconnecter`, {
              title: 'Session expirée',
              autoHideDelay: 7000,
              appendToast: false
            })
          })
          return is_contained
        }
      }

      for (var j = 0; j < groupsList.length; j++) {
        if (userGroupsArray.indexOf(groupsList[j]) > -1) {
          is_contained = true
        }
      }
      return is_contained
    }
  }
}
</script>
