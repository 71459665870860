<template>
  <div id="app">
    <b-navbar class="gavc-base-navbar" toggleable="lg" fixed="top">

      <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

      <b-navbar-brand to="/">
        <img class='brand_logo' :src="require('@/assets/images/app/logo-gavc.png')" alt="Plateforme de cession">
      </b-navbar-brand>

      <b-collapse is-nav id="nav_collapse" >
        <b-navbar-nav v-if="isAuthenticated" class="ml-auto mt-2">
          <b-nav-item-dropdown
            class="notification-dropdown d-none d-lg-block"
            right
          >
            <template
              slot="button-content">
              <div href="#" class="gavc-btn-circle--notif" @click="markAllNotificationsAsRead">
                <div class="gavc-btn-circle gavc-btn-circle--notif__bubble">
                  <i class="gavc-icon gavc-icon-notification"></i>
                  <p v-if="nbUnreadNotifications > 0" class="gavc-btn-circle--notif__number">{{nbUnreadNotifications}}</p>
                </div>
              </div>
            </template>
            <b-dropdown-item v-for="notification in notifications" :key="notification.id" class="notification-dropdown-item" @click="clickOnNotification(notification)">
              <div class="message" style="width: 100%;">
                <div>
                  <small class="text-muted float-right">{{calculateDateDifference(notification.timestamp)}}</small>
                </div>
                <div v-bind:class="['text-truncated', (!notification.clicked) ? 'font-weight-bold' : 'text-muted']">
                  <span v-if="!notification.clicked" class="notification-dot mr-2"></span>{{notificationTitleRender(notification.verb)}}
                </div>
                <div class="text-truncated small text-muted mt-1">
                  {{notificationDescriptionRender(notification)}}
                </div>
              </div>
            </b-dropdown-item>
            <b-dropdown-item class="text-center" @click="$router.push('/notifications')" style="font-size: 0.8rem;">
              Voir toutes
            </b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item v-if="userGroupContains(['CEDANT'])" class="gavc-navbar-items mt-2" to="/cedant/tableau-de-bord"> Mon tableau de bord </b-nav-item>
          <b-nav-item v-if="userGroupContains(['REPRENEUR'])" class="gavc-navbar-items mt-2" to="/repreneur/tableau-de-bord"> Mon tableau de bord </b-nav-item>
          <b-nav-item-dropdown  class="gavc-navbar-items mt-2" text="Profil" right>
            <b-dropdown-item class="navbar_dropdown_items" to="/profil/mot-de-passe">Modifier votre mot de passe</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item class="gavc-navbar-items mt-2 mr-4" to="/deconnexion"> Déconnexion </b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav v-else class="ml-auto">
          <b-nav-item class="mr-4 mt-2" href="https://garance-a-vos-cotes.fr" target="_blank">
            <i class="gavc-icon gavc-icon-arrow-left" ></i>  Retour au site
          </b-nav-item>
          <b-nav-item>
            <button 
              class="gavc-btn"
              @click="$router.push('/se-connecter')"
            >
              Se connecter
            </button>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div id="app-body">
      <router-view/>
      <globalErrorModalComponent />
      <LoadingComponent />
      <cookiesBandeauComponent />
    </div>
    <footerComponent />
  </div>
</template>

<script>
import { APIConnected } from '@/api/APIConnected'
import globalErrorModalComponent from '@/components/globalErrorModalComponent'
import LoadingComponent from '@/components/LoadingComponent'
import cookiesBandeauComponent from '@/components/layout/cookiesBandeauComponent'
import notificationsMixins from '@/mixins/notificationsMixins'

const apiConnectedService = new APIConnected()
const footerComponent = () => import('@/components/footerComponent')


export default {
  name: 'App',
  mixins: [notificationsMixins],
  data () {
    const now = this.$dayjs()
    return {
      notifications: [],
      nbUnreadNotifications: 0,

      now: now
    }
  },
  components : {
    cookiesBandeauComponent,
    footerComponent,
    globalErrorModalComponent,
    LoadingComponent
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    },
    token () {
      return this.$store.getters.TOKEN
    },
    userFirstName () {
      return this.$store.getters.getUserFirstName
    },
    userLastName () {
      return this.$store.getters.getUserLastName
    },
  },
  watch : {
    notifications: function (newNotifications) {
      this.nbUnreadNotifications = 0
      for (var i = 0; i < newNotifications.length; i++) {
        if (newNotifications[i].unread) {
          this.nbUnreadNotifications ++
        }
      }
    },
    isAuthenticated: function (value) {
      if (value) {
        this.getTenLastNotifications()
      }
    }
  },

  created: function () {
    if (this.token) {
      this.getTenLastNotifications()
    }
  },

  methods: {
    calculateDateDifference(notification_date) {
      return this.notificationDateRender((this.now - this.$dayjs(notification_date))/1000)
    },

    clickOnNotification (notification) {
      for (var i=0; i < this.notifications.length; i++) {
        if (this.notifications[i].id == notification.id) {
          this.notifications[i].clicked = true
        }
      }
      apiConnectedService.markNotificationAsClicked(this.token, notification.id)
      .then(() => {
        this.$router.push(this.notificationLinkRender(notification.actor, notification.verb))
      })
    },

    getTenLastNotifications () {
      apiConnectedService.getTenLastNotifications(this.token)
      .then((result) => {
          this.notifications  = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
    },

    markAllNotificationsAsRead () {
      apiConnectedService.markAllNotificationsAsRead(this.token)
      .then(() => {
        this.nbUnreadNotifications = 0
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
    },

    userGroupContains(groupsList) {
      var userGroups = this.$store.getters.getUserGroups
      var userGroupsArray = []
      var is_contained = false
      if (userGroups) {
        try {
          var userGroupsJson = JSON.parse(userGroups)
          for (var i = 0; i < userGroupsJson.length; i++) {
            userGroupsArray.push(
              userGroupsJson[i]['name']
            )
          }
        }
        catch(error) {
          this.$store.dispatch('AUTH_LOGOUT').then(() => {
            this.$router.push('/se-connecter')
            this.$bvToast.toast(`Votre session a expiré, veuillez vous reconnecter`, {
              title: 'Session expirée',
              autoHideDelay: 7000,
              appendToast: false
            })
          })
          return is_contained
        }
      }

      for (var j = 0; j < groupsList.length; j++) {
        if (userGroupsArray.indexOf(groupsList[j]) > -1) {
          is_contained = true
        }
      }
      return is_contained
    }
  }
}
</script>


<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/styles/main.scss';
@import "./assets/fonts/Larken/stylesheet.css";

html, body { overflow-x: hidden;}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app-body {
  margin-top: 60px;
  position: relative;
}


body {
  background-color: white !important;
  font-family: "Wix Madefor Text", sans-serif !important;
  padding-right: 0 !important;
}

.modal-content {
  background-color: white !important;
}

.tooltip .tooltip-inner{
  max-width: 500px !important;
}

.pointer-icon {
  cursor: pointer;
}

.cursor-table tr {
  cursor: pointer;
}

.masthead {
  padding-top: 2%;
  padding-bottom: 2%;
}


.gavc-base-navbar {
  background-color: white;
  border-bottom: 1px solid $green-darken-1;
  height: 80px;
  line-height: 2;
  padding-right: 0px !important;
  z-index: 1005;
}

#app-body {
  margin-top: 100px;
}

.notification-dropdown > .dropdown-toggle::after {
  content: none
}

.notification-dropdown > .dropdown-menu {
  background-color: $white-darken-1; 
}

.notification-dropdown-item {
  font-size: 0.8rem;
}

.notification-dropdown-item > .dropdown-item:hover {
  font-size: 0.8rem;
  background-color: $white-lighten-1; 
}

.notification-dot {
   height: 11px;
   width: 11px;
   background-color: $green-darken-1;
   border-radius: 50%;
   display: inline-block;
}

.brand_logo {
  height: 30px;
}

#nav_collapse {
  background-color: white;
  padding-left: 10px;
  z-index: 1001;
}

.gavc-navbar-items {
  font-size: 16px;
  margin-left: 10px;
}

.gavc-navbar-items > a {
  color: #33304C !important;
}

/* Used because basic gavc input have a not strong disabled */
.gavc-input-strong-disabled:disabled {
  opacity: 0.6 !important;
}

.gavc-bootstrap-input {
  background-color: $white-darken-1 !important;
  padding: 1.2em !important;
  font-size: .875rem !important;
  border-radius: 6px !important;
}

.form-error {
  color: #B1060A;
  font-size: 0.8em;
  font-style: italic;
}

.subsection-button {
  background-color: $white-darken-1 !important;
  border-bottom: 0.2px solid $white-darken-2 !important;
  border-left: None !important;
  border-radius: 0px !important;
  border-right: None !important;
  border-top: None !important;
  box-shadow: none;
  color: #33304c !important;
  font-size: 1.2rem !important;
  text-align: left !important;
}
.subsection-button:active,
.subsection-button:focus,
.subsection-button:hover,
.subsection-button:focus-visible {
  background-color: $white-darken-1 !important;
  border-bottom: 0.2px solid $white-darken-2 !important;
  border-left: None !important;
  border-radius: 0px !important;
  border-right: None !important;
  border-top: None !important;
  box-shadow: none !important;
  color: #33304c !important;
  font-size: 1.2rem !important;
  text-align: left !important;
}

.collapsed>.when-opened,
:not(.collapsed)>.when-closed {
  display: none;
}


@media (min-width: 576px) {
  .brand_logo {
    height: 35px;
  }
  .help-title {
    font-size: 18px;
  }
  .help-subtitle {
    font-size: 18px;
  }
}

@media (min-width: 992px) {
  .gavc-base-navbar {
    height: 100px !important;
  }
  #app-body {
    margin-top: 120px;
  }
  .brand_logo {
    height: 50px;
  }
  #nav_collapse {
    background-color: transparent;
  }
}

/* ----------- INLINE FORM -----------------
/* Style the form - display items horizontally */
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

/* Style the input fields */
.form-inline input {
  vertical-align: middle;
  margin: 5px 10px 5px 0;
  padding: 10px;
  width: auto !important;
}

/* Add responsiveness - display the form controls vertically instead of horizontally on screens that are less than 800px wide */
@media (max-width: 800px) {
  .form-inline input {
    margin: 10px 0;
  }

  .form-inline {
    flex-direction: column;
    align-items: stretch;
  }
}


// ------------------------------- ERASE GAVC-STYLE with new GARANCE STYLE ------------------------


// COLORS 
.gavc-bg-bizarre {
  background-color: $white-lighten-1 !important;
}
.gavc-text-green {
  color: $green-darken-1 !important;
}

// TYPO
.gavc-h1 {
  font-family: Larken, sans-serif !important;
}


// BUTTONS
.gavc-btn {
    background-color: $green-darken-1;
    border: none;
}
.gavc-btn:hover {
    background-color: $green-lighten-2;
}
.gavc-btn:focus {
    background-color: $green-darken-1 !important;
    box-shadow: none !important;
}

.gavc-btn--bone {
  border: 2px solid $white-darken-1 !important;
  background-color: $white-darken-1 !important;
  color: black;
}

.gavc-carousel-overlay__close-btn {
  background-color: $green-darken-1 !important;
  border: 2px solid $green-darken-1 !important;
}

.gavc-btn-circle--chevron {
  background-color: $green-darken-1 !important;
  border: 2px solid $green-darken-1 !important;
}

// INPUTS
.gavc-input {
  background-color: $white-darken-1 !important;
}

.gavc-input:hover {
    box-shadow: 0 0 0 2px $white-darken-2 !important;
}

.gavc-input:focus {
    outline: 0;
    box-shadow: 0 0 0 2px $white-darken-2 !important;
}

.gavc-input--icon {
  box-shadow: 0 0 0 3px $white-darken-2;
}

.gavc-textarea {
  background-color: $white-darken-1 !important;
}

.gavc-textarea:hover {
    box-shadow: 0 0 0 2px $white-darken-2 !important;
}

.gavc-textarea:focus {
    outline: 0;
    box-shadow: 0 0 0 2px $white-darken-2 !important;
}

.gavc-radio-icon label {
    background-color: $white-darken-1 !important;
}

.gavc-radio-icon input:checked~label {
    background-color: $green-darken-1 !important;
}

.gavc-radio-icon input:checked~label .gavc-icon {
  color: $white-lighten-1 !important;
}

.gavc-checkbox input:checked+label:before, .gavc-radio input:checked+label:before {
  background: $green-darken-1 !important;
  border-color: $green-darken-1 !important;
}


.gavc-field--icon .gavc-icon {
  background-color: $white-darken-2;
}

.gavc-input--icon:focus+.gavc-icon {
  background-color: $white-darken-2;
}

.gavc-upload-file__input {
  background-color: $white-darken-1 !important;
  border: 2px dashed $white-darken-2;
}


// CARD

.gavc-card {
  background-color: $white-lighten-1 !important;
  border: 2px solid $white-darken-1 !important;
}

.gavc-card-offer {
  background-color: $white-lighten-1 !important;
  border: 2px solid $white-darken-1 !important;
}

.gavc-card-offer__content {
  background-color: $white-darken-1 !important;
}

.gavc-card-offer__header .gavc-question .gavc-h1 {
  color: $green-darken-1 !important;
}

.gavc-card-progress-bar {
    background-color: $white-darken-1;
}

.gavc-card-progress-bar--active {
  background-color: $green-darken-1 !important;
}

.gavc-card-row:before {
  background-color: $white-darken-1;
}

.gavc-card-row__status .gavc-icon {
  background-color: $white-lighten-1;
  color: $green-darken-1;
}

.gavc-card-row__status--text {
  color: $green-darken-1;
}

.gavc-card-candidacy:before  {
  background-color: $white-darken-1;
}
.gavc-card-candidacy__infos .gavc-icon {
  background-color: $white-lighten-1;
}
.gavc-card-candidacy__infos--text {
  background-color: $white-lighten-1;
}
.gavc-card-candidacy__contact .gavc-icon  {
  background-color: $white-lighten-1;
}

.gavc-card-candidacy__status .gavc-icon {
  color: $green-darken-1;
  background-color: $white-lighten-1;
}

.gavc-card-candidacy__status p {
  color: $green-darken-1;
}

// STEPPER

.gavc-stepper {
    background-color: $green-darken-1 !important;
}

.gavc-stepper .gavc-step--is-actif {
    background-color: $green-lighten-2 !important;
}

.gavc-stepper .gavc-step .gavc-tooltip .gavc-tooltip-icon {
    background-color: $green-darken-1 !important;
}

.gavc-stepper-dashboard__item--is-active .gavc-step-title {
  color: $green-darken-1 !important;
}

.gavc-stepper-dashboard__item--is-active .gavc-step-number {
  background-color: $green-darken-1 !important;
  border: 2px solid $green-darken-1 !important;
}

// ICONS
.gavc-tooltip .gavc-tooltip-icon  {
    background-color: $green-lighten-2 !important;
}

// NOTIF
.gavc-btn-circle--notif__bubble {
  background-color: $white-darken-1 !important;
  box-shadow: 0 0 0 2px $white-lighten-1 !important;
}

.gavc-btn-circle--notif__number {
  background-color: $green-darken-1;
  box-shadow: 0 0 0 2px $green-lighten-2;
}

// CAROUSSEL




</style>
