import axios from 'axios'
import { apiBaseUrl } from '@/variables/localVariables'

export class APIDisconnectedService {

  confirmResetPassword (token, password) {
    const url = apiBaseUrl + `/password-reset/confirm`
    var headers = {
      'Content-Type': 'application/json'
    }
    return axios.post(
      url,
      {
        'token': token,
        'password': password
      },
      headers
    )
  }

  findCompanyBySiren (siren) {
    const url = apiBaseUrl + `/company-by-siren/${siren}`
    const headers = {
      'Content-Type': 'application/json',
    }
    return axios.get(url, {'headers': headers })
  }

  findUserByUsername (username) {
    const url = apiBaseUrl + `/user-by-username/${username}`
    const headers = {
      'Content-Type': 'application/json',
    }
    return axios.get(url, {'headers': headers })
  }

  getAllAnnonces (url, params) {
    const headers = {
      'Content-Type': 'application/json',
    }
    return axios.get(url, {'headers': headers, params: params})
  }

  getAnnoncePublicProfile (id) {
    const url = apiBaseUrl + `/get-annonce-public-profile/${id}`
    const headers = {
      'Content-Type': 'application/json',
    }
    return axios.get(url, {'headers': headers })
  }

  getToken (user) {
    const url = apiBaseUrl + `/auth/token`
    return axios.post(url, user)
  }

  modifyPassword (token, old_password, new_password, new_password_confirmed) {
    const url = apiBaseUrl + `/modify-password`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    var data = {
      'old_password': old_password,
      'new_password': new_password,
      'new_password_confirmed': new_password_confirmed,
    }

    return axios.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  refreshToken (refreshToken) {
    const url = apiBaseUrl + `/auth/token/refresh`
    return axios.post(url, {'refresh': refreshToken})
  }

  registerCedant (data) {
    const url = apiBaseUrl + `/register/cedant`
    const headers = {
      'Content-Type': 'application/json',
    }
    return axios.post(
      url,
      data,
      {
        'headers': headers,
      }
    )
  }

  registerRepreneur (data) {
    const url = apiBaseUrl + `/register/repreneur`
    const headers = {
      'Content-Type': 'application/json',
    }
    return axios.post(
      url,
      data,
      {
        'headers': headers,
      }
    )
  }

  resetPassword (email) {
    const url = apiBaseUrl + `/password-reset`
    var headers = {
      'Content-Type': 'application/json'
    }
    return axios.post(
      url,
      {'email': email},
      headers
    )
  }
}
