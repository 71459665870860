import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store'

import Home from '../views/Home.vue'

const EntrepriseCedee = () => import("../views/audit/EntrepriseCedee")
const PaiementPrix = () => import("../views/audit/PaiementPrix")
const SituationJuridique = () => import("../views/audit/SituationJuridique")
const CedantCompanyProfile = () => import('../views/cedant/CedantCompanyProfile')
const CedantEntrepriseCedeeProfile = () => import('../views/cedant/CedantEntrepriseCedeeProfile')
const CedantDashboard = () => import('../views/cedant/CedantDashboard')
const CedantProfile = () => import('../views/cedant/CedantProfile')
const EditAnnonce = () => import('../views/cedant/annonce/EditAnnonce')
//const OffreCessionCedantProfile = () => import('../views/cedant/offreCession/OffreCessionCedantProfile')
const Closing = () => import('../views/closing/Closing')
const Pourparler = () => import('../views/pourparler/Pourparler')
const notFound = () => import('../views/notFound')
const Notifications = () => import('../views/notification/Notifications')
const RepreneurCompanyProfile = () => import('../views/repreneur/RepreneurCompanyProfile')
const RepreneurDashboard = () => import('../views/repreneur/RepreneurDashboard')
const RepreneurProfile = () => import('../views/repreneur/RepreneurProfile')
const Login = () => import('../views/user/Login')
const Logout = () => import('../views/user/Logout')
const OffreCessionPublicProfile = () => import('../views/user/offreCession/OffreCessionPublicProfile')
const SearchOffreCession = () => import('../views/user/offreCession/SearchOffreCession')
const PasswordReset = () => import('../views/user/PasswordReset')
const PasswordResetConfirm = () => import('../views/user/PasswordResetConfirm')
const ModifyPassword = () => import('../views/user/profile/ModifyPassword')
const AccountSaved = () => import('../views/user/registration/AccountSaved')
const CedantAccueilRegistration = () => import('../views/user/registration/CedantAccueilRegistration')
const CedantRegister = () => import('../views/user/registration/CedantRegister')
const RegistrationCedantValidation = () => import('../views/user/registration/RegistrationCedantValidation')
const RepreneurAccueilRegistration = () => import('../views/user/registration/RepreneurAccueilRegistration')
const RepreneurRegister = () => import('../views/user/registration/RepreneurRegister')



Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/se-connecter')
}


const isUserGroup = (group_name) => {
  var userGroups = store.getters.getUserGroups
  var userGroupsArray = []
  var is_contained = false
  if (userGroups) {
    try {
      var userGroupsJson = JSON.parse(userGroups)
      for (var i = 0; i < userGroupsJson.length; i++) {
        userGroupsArray.push(
          userGroupsJson[i]['name']
        )
      }
    }
    catch(error) {
      return is_contained
    }
  }

  for (var j = 0; j < group_name.length; j++) {
    if (userGroupsArray.indexOf(group_name[j]) > -1) {
      is_contained = true
    }
  }
  return is_contained
}

const isConnectedCedant = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    if (isUserGroup(['CEDANT'])) {
      next()
      return
    }
    else {
      next('/')
    }
  }
  else {
    next('/se-connecter')
  }
}

const isConnectedRepreneur = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    if (isUserGroup(['REPRENEUR'])) {
      next()
      return
    }
    else {
      next('/')
    }
  }
  else {
    next('/se-connecter')
  }
}




const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/404',
    name: 'notFound',
    component: notFound,
  },
  {
    path: '/audit/entreprise-cedee/:auditId',
    name: 'EntrepriseCedee',
    component: EntrepriseCedee,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/audit/paiement-prix/:paiementPrixId',
    name: 'PaiementPrix',
    component: PaiementPrix,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/audit/situation-juridique/:situationJuridiqueId',
    name: 'SituationJuridique',
    component: SituationJuridique,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/cedant/editer-mon-profil',
    name: 'CedantProfile',
    component: CedantProfile,
    beforeEnter: isConnectedCedant
  },
  {
    path: '/cedant/ma-societe',
    name: 'CedantCompanyProfile',
    component: CedantCompanyProfile,
    beforeEnter: isConnectedCedant
  },
  {
    path: '/cedant/mon-entreprise-a-ceder',
    name: 'CedantEntrepriseCedeeProfile',
    component: CedantEntrepriseCedeeProfile,
    beforeEnter: isConnectedCedant
  },
  /*{
    path: '/cedant/offre-cession/:offreCessionId/profil',
    name: 'OffreCessionCedantProfile',
    component: OffreCessionCedantProfile,
    beforeEnter: isConnectedCedant
  },
  {
    path: '/cedant/offre-cession/creation',
    name: 'CreateOffreCession',
    component: CreateOffreCession,
    beforeEnter: isConnectedCedant
  },*/
  {
    path: '/cedant/offre-cession/annonce/:annonceId/edition',
    name: 'EditAnnonce',
    component: EditAnnonce,
    beforeEnter: isConnectedCedant
  },
  {
    path: '/cedant/tableau-de-bord',
    name: 'CedantDashboard',
    component: CedantDashboard,
    beforeEnter: isConnectedCedant
  },
  {
    path: '/closing/:closingId',
    name: 'Closing',
    component: Closing,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/deconnexion',
    name: 'Logout',
    component: Logout,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/inscription/validation',
    name: 'AccountSaved',
    component: AccountSaved,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/inscription/cedant/validation',
    name: 'RegistrationCedantValidation',
    component: RegistrationCedantValidation,
    beforeEnter: isConnectedCedant
  },
  {
    path: '/inscription/cedant',
    name: 'CedantRegister',
    component: CedantRegister,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/inscription/accueil/cedant',
    name: 'CedantAccueilRegistration',
    component: CedantAccueilRegistration,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/inscription/accueil/repreneur',
    name: 'RepreneurAccueilRegistration',
    component: RepreneurAccueilRegistration,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/inscription/repreneur',
    name: 'RepreneurRegister',
    component: RepreneurRegister,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/mot-de-passe-oublie',
    name: 'PasswordReset',
    component: PasswordReset,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/mot-de-passe-oublie-confirmation',
    name: 'PasswordResetConfirm',
    component: PasswordResetConfirm,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/offres-de-cession/public/:annonceId',
    name: 'OffreCessionPublicProfile',
    component: OffreCessionPublicProfile
  },
  {
    path: '/offres-de-cession/recherche',
    name: 'SearchOffreCession',
    component: SearchOffreCession
  },
  {
    path: '/pourparler/:pourparlerId',
    name: 'Pourparler',
    component: Pourparler,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/profil/mot-de-passe',
    name: 'ModifyPassword',
    component: ModifyPassword,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/repreneur/mon-entreprise',
    name: 'RepreneurCompanyProfile',
    component: RepreneurCompanyProfile,
    beforeEnter: isConnectedRepreneur
  },
  {
    path: '/repreneur/editer-mon-profil',
    name: 'RepreneurProfile',
    component: RepreneurProfile,
    beforeEnter: isConnectedRepreneur
  },
  {
    path: '/repreneur/tableau-de-bord',
    name: 'RepreneurDashboard',
    component: RepreneurDashboard,
    beforeEnter: isConnectedRepreneur
  },
  {
    path: '/se-connecter',
    name: 'Login',
    component: Login,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '*',
    redirect: '/404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
